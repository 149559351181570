<template>
  <div class="mentor-mentorships">
    <b-row class="match-height">
      <b-col xs="12" md="3">
        <welcome-message />
      </b-col>
      <b-col xs="12" md="9">
        <current-mentorship :title="$t('My current mentorship')" :mentor="mentor" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <mentorship-requests class="mentorship-requests" :mentor="mentor" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <previous-mentorships class="previous-mentorships" :mentor="mentor" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <need-help />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardGroup,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BRow
} from 'bootstrap-vue'
import CurrentMentorship from '@mentoring-platform/views/components/blocks/MentorMentorships/CurrentMentorship.vue'
import MentorshipRequests from '@mentoring-platform/views/components/blocks/MentorMentorships/MentorshipRequests.vue'
import PreviousMentorships from '@mentoring-platform/views/components/blocks/MentorMentorships/PreviousMentorships.vue'
import WelcomeMessage from '@mentoring-platform/views/components/blocks/WelcomeMessage.vue'
import NeedHelp from '@mentoring-platform/views/components/blocks/NeedHelp.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardGroup,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BRow,
    CurrentMentorship,
    MentorshipRequests,
    NeedHelp,
    PreviousMentorships,
    WelcomeMessage
  },
  data() {
    return {

    }
  },
  computed: {
    mentor() {
      return this.$store.getters['user/getUserData'].mentor
    }
  }
}
</script>

<style lang="scss">
.mentor-mentorships{

  .mentorship-requests .card-body, .previous-mentorships .card-body {
    overflow: auto;
  }

  .no-data-card-body {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}
</style>
