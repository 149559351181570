<template>
  <div>
    <b-card-body class="d-flex flex-column align-items-center">
      <b-avatar
        size="60"
        src=""
        class="mt-1 mb-2 cursor-pointer"
        @click.native="showMenteeDetailsModal(mentorship)"
      >
      </b-avatar>
      <b-card-text class="text-primary cursor-pointer" @click="showMenteeDetailsModal(mentorship)">
        {{ menteeFullName }}
      </b-card-text>
      <b-card-text>
        {{ mentee.user.professional_title }}
      </b-card-text>
    </b-card-body>
    <mentee-details-modal v-if="mentorship" ref="mentee-details-modal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BCardBody,
  BCardText
} from 'bootstrap-vue'
import MenteeDetailsModal from '@mentoring-platform/views/components/blocks/MentorMentorships/MenteeDetailsModal.vue'

export default {
  components: {
    BAvatar,
    BCardBody,
    BCardText,
    MenteeDetailsModal
  },
  props: {
    mentorship: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    mentee() {
      return this.mentorship.mentee
    },
    menteeFullName() {
      return this.mentee.user.name + ' ' + this.mentee.user.surname
    },
    getUserAvatar() {
      return this.$store.getters['user/getUserAvatar']
    }
  },
  methods: {
    showMenteeDetailsModal(mentorship) {
      this.$refs['mentee-details-modal'].show(mentorship)
    }
  }
}
</script>

<style scoped>

</style>
