<template>
  <b-modal
    id="reject-request-modal"
    ref="reject-request-modal"
    centered
    size="lg"
    :title="$t('Confirm rejection')"
    title-class="font-weight-bolder"
    cancel-variant="outline-primary"
    :cancel-title="$t('Cancel')"
    :ok-title="$t('Confirm rejection')"
    @ok.prevent="rejectRequest"
  >
    <b-card-text>
      {{ $t('Reject request info') }}
    </b-card-text>
    <validation-observer ref="reject-request-observer">
      <validation-provider
        v-slot="{ errors }"
        name="reasonForRejectingRequest"
        rules="min:3|max:500"
        mode="lazy"
      >
        <b-form-group>
          <b-form-textarea
            id="reason-for-rejecting-request"
            v-model="reasonForRejectingRequest"
            rows="3"
            no-resize
            :placeholder="$t('Your message (optional)')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BFormGroup,
  BFormTextarea,
  BModal,
  BRow
} from "bootstrap-vue";
import { useMentorMentorships } from '@mentoring-platform/composables'
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BCardText,
    BCol,
    BFormGroup,
    BFormTextarea,
    BModal,
    BRow,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      request: null,
      reasonForRejectingRequest: null
    }
  },
  methods: {
    show(request) {
      this.request = request
      this.reasonForRejectingRequest = null
      this.$refs['reject-request-modal'].show()
    },
    rejectRequest() {
      this.$refs['reject-request-observer'].validate().then(async success => {
        if(success) {
          this.$refs['reject-request-modal'].hide()
          this.rejectMentorshipRequest(this.request, this.reasonForRejectingRequest)
            .then(response => {
              this.$bus.$emit('mentorship-request-rejected')
              this.$root.$bvToast.toast('Mentorship request rejected', {
                title: this.$t('Confirmation'),
                variant: 'success',
                solid: true,
              })
            })
            .catch(error => {
              this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
                title: this.$t('Caution'),
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    }
  },
  setup() {
    const { rejectMentorshipRequest } = useMentorMentorships()

    return {
      rejectMentorshipRequest
    }
  }
};
</script>

<style lang="scss">

</style>