var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('My packages'))+" ")])],1),(_vm.userPackages.length)?_c('b-card-body',[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.userPackages,"responsive":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm._f("upper")(label))+" ")]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.description))+" ")]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.packageStatusToColorVariant(data.item.status)}},[_vm._v(" "+_vm._s(_vm.packageStatusToText(data.item.status))+" ")])]}},{key:"cell(mentor)",fn:function(ref){
var item = ref.item;
return [(item.status == _vm.packageStatuses.PACKAGE_STATUS_RUNNING || item.status == _vm.packageStatuses.PACKAGE_STATUS_COMPLETED)?[_c('div',{staticClass:"d-flex flex-row mentorship-info"},[_c('div',{staticClass:"mr-1"},[_c('b-avatar',{staticClass:"grayscale-avatar",attrs:{"size":"40","src":item.mentor.user.photo_full_path}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('b-link',{attrs:{"to":{ name: 'mentor-page', params: { id: item.mentor.id } }}},[_vm._v(" "+_vm._s(_vm.getMentorVisibleFullName(item.mentor))+" ")]),_c('div',{staticClass:"footnote"},[_vm._v(" "+_vm._s(_vm._f("formatGreekDate")(item.started_at_date,{ month: 'numeric', year: 'numeric', day: '2-digit' }))+" - "+_vm._s(_vm._f("formatGreekDate")(item.ended_at_date,{ month: 'numeric', year: 'numeric', day: '2-digit' }))+" ")])],1)])]:_vm._e()]}},{key:"cell(rete-mentorship)",fn:function(ref){
var item = ref.item;
return [(item.status == _vm.packageStatuses.PACKAGE_STATUS_RUNNING || item.status == _vm.packageStatuses.PACKAGE_STATUS_COMPLETED)?_c('b-button',{attrs:{"variant":"primary","href":_vm.rateMentorshipUrls.menteeUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('Rate mentorship'))+" ")]):_vm._e()]}}],null,false,2009593971)})],1):_c('b-card-body',{staticClass:"mb-3",attrs:{"body-class":"no-data-card-body"}},[_c('feather-icon',{attrs:{"icon":"PackageIcon","size":"25"}}),_c('b-card-text',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('You do not have any purchased package yet.'))+" ")]),(_vm.$can('read', 'mentee'))?_c('b-button',{attrs:{"variant":"primary","to":{ name: 'mentoring-packages' }}},[_vm._v(" "+_vm._s(_vm.$t('View mentoring packages'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }