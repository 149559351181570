<template>
  <b-card :title="$t('Pending mentorship requests')">
    <div v-if="pendingMentorshipRequests.length">
      <pending-mentorship-requests-table
        :mentorship-requests="pendingMentorshipRequests"
        @show-mentor-details="showMentorDetails"
      />
    </div>
    <div
      v-else
      class="no-data-card-body"
    >
      <feather-icon
        icon="ThumbsUpIcon"
        size="25"
      />
      <b-card-text>
        {{ $t('You do not have any pending request!') }}
      </b-card-text>
    </div>
    <mentor-details-modal ref="mentor-details-modal" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText
} from 'bootstrap-vue'
import MentorDetailsModal from '@mentoring-platform/views/components/blocks/MenteeMentorships/MentorDetailsModal.vue'
import PendingMentorshipRequestsTable from '@mentoring-platform/views/components/blocks/MenteeAdmin/PendingMentorshipRequestsTable.vue'
import { useMenteeMentorships } from '@mentoring-platform/composables'

export default {
  components: {
    BCard,
    BCardText,
    MentorDetailsModal,
    PendingMentorshipRequestsTable
  },
  props: {
    mentee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    showMentorDetails(request) {
      this.$refs['mentor-details-modal'].show(request)
    }
  },
  setup(props) {
    const { pendingMentorshipRequests, getMentorshipRequests } = useMenteeMentorships()
    getMentorshipRequests(props.mentee)

    return {
      pendingMentorshipRequests
    }
  }
}
</script>

<style scoped>

</style>
