<template>
  <b-modal
    id="approve-request-modal"
    ref="approve-request-modal"
    centered
    size="lg"
    :title="$t('Confirm approval')"
    title-class="font-weight-bolder"
    cancel-variant="outline-primary"
    :cancel-title="$t('Cancel')"
    :ok-title="$t('Confirm approval')"
    @ok="approveRequest"
  >
    <b-card-text>
      {{ $t('Approve request info') }}
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BFormGroup,
  BFormTextarea,
  BModal,
  BRow
} from "bootstrap-vue";
import { useMentorMentorships } from '@mentoring-platform/composables'

export default {
  components: {
    BButton,
    BCardText,
    BCol,
    BFormGroup,
    BFormTextarea,
    BModal,
    BRow
  },
  data() {
    return {
      request: null
    }
  },
  methods: {
    show(request) {
      this.request = request
      this.$refs["approve-request-modal"].show();
    },
    approveRequest() {
      this.approveMentorshipRequest(this.request)
        .then(response => {
          this.$bus.$emit('mentorship-request-approved')
          this.$root.$bvToast.toast(this.$t('Mentorship request accepted!'), {
            title: this.$t('Congratulations!'),
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          this.$bus.$emit('mentorship-request-rejected')
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    }
  },
  setup() {
    const { approveMentorshipRequest } = useMentorMentorships()

    return {
      approveMentorshipRequest
    }
  }
};
</script>

<style lang="scss">

</style>
