<template>
  <b-card>
    <b-tabs>
      <b-tab :title="$t('Mentoring')" active>
        <b-card-title>
          {{ $t('MentorshipRequests') }}
        </b-card-title>
        <div v-if="mentrorshipRequestsForMentoring.length">
          <mentorship-requests-table
            :mentorship-requests="mentrorshipRequestsForMentoring"
            @approve-request="approveRequest"
            @reject-request="rejectRequest"
            @show-mentee-details="showMenteeDetails"
          />
        </div>
        <div v-else class="no-data-card-body">
          <feather-icon
            icon="ThumbsUpIcon"
            size="25"
          />
          <b-card-text>
            {{ $t('You do not have any pending mentoring request!') }}
          </b-card-text>
        </div>
      </b-tab>
      <b-tab :title="$t('Coaching')" v-if="mentor.is_coach">
        <b-card-title>
          {{ $t('MentorshipRequests') }}
        </b-card-title>
        <div v-if="mentrorshipRequestsForCoaching.length">
          <mentorship-requests-table
            :mentorship-requests="mentrorshipRequestsForCoaching"
            @approve-request="approveRequest"
            @reject-request="rejectRequest"
            @show-mentee-details="showMenteeDetails"
          />
        </div>
        <div v-else class="no-data-card-body">
          <feather-icon
            icon="ThumbsUpIcon"
            size="25"
          />
          <b-card-text>
            {{ $t('You do not have any pending coaching request!') }}
          </b-card-text>
        </div>
      </b-tab>
    </b-tabs>
    <approve-request-modal ref="approve-request-modal" />
    <reject-request-modal ref="reject-request-modal" />
    <mentee-details-modal ref="mentee-details-modal" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardTitle,
  BTab,
  BTabs
} from 'bootstrap-vue'
import ApproveRequestModal from '@mentoring-platform/views/components/blocks/MentorMentorships/ApproveRequestModal.vue'
import RejectRequestModal from '@mentoring-platform/views/components/blocks/MentorMentorships/RejectRequestModal.vue'
import MenteeDetailsModal from '@mentoring-platform/views/components/blocks/MentorMentorships/MenteeDetailsModal.vue'
import MentorshipRequestsTable from '@mentoring-platform/views/components/blocks/MentorMentorships/MentorshipRequestsTable.vue'
import { useMentorMentorships } from '@mentoring-platform/composables'

export default {
  components: {
    ApproveRequestModal,
    BCard,
    BCardText,
    BCardTitle,
    BTab,
    BTabs,
    MenteeDetailsModal,
    MentorshipRequestsTable,
    RejectRequestModal
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    mentrorshipRequestsForMentoring() {
      return this.pendingMentorshipRequests.filter(request => request.is_coaching == false)
    },
    mentrorshipRequestsForCoaching() {
      return this.pendingMentorshipRequests.filter(request => request.is_coaching == true)
    }
  },
  beforeMount() {
    this.$bus.$on('mentorship-request-approved', this.refreshList)
    this.$bus.$on('mentorship-request-rejected', this.refreshList)
  },
  beforeDestroy() {
    this.$bus.$off('mentorship-request-approved', this.refreshList)
    this.$bus.$off('mentorship-request-rejected', this.refreshList)
  },
  methods: {
    refreshList() {
      this.getMentorshipRequests(this.mentor)
    },
    approveRequest(request) {
      this.$refs['approve-request-modal'].show(request)
    },
    rejectRequest(request) {
      this.$refs['reject-request-modal'].show(request)
    },
    showMenteeDetails(request) {
      this.$refs["mentee-details-modal"].show(request);
    },
  },
  setup(props) {
    const { pendingMentorshipRequests, getMentorshipRequests } = useMentorMentorships()
    getMentorshipRequests(props.mentor)

    return {
      pendingMentorshipRequests,
      getMentorshipRequests
    }
  }
}
</script>

<style scoped>

</style>
