<template>
  <div class="mentee-mentorships">
    <b-row class="match-height">
      <b-col xs="12" md="3">
        <welcome-message />
      </b-col>
      <b-col xs="12" md="9">
        <current-mentorship :title="$t('My current mentorship')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <pending-mentorship-requests :mentee="mentee" />
      </b-col>
    </b-row>
    <b-row>
      <b-col id="my-packages">
        <my-packages />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <need-help />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow
} from 'bootstrap-vue'
import CurrentMentorship from '@mentoring-platform/views/components/blocks/MenteeMentorships/CurrentMentorship.vue'
import MyPackages from '@mentoring-platform/views/components/blocks/MenteeMentorships/MyPackages.vue'
import WelcomeMessage from '@mentoring-platform/views/components/blocks/WelcomeMessage.vue'
import NeedHelp from '@mentoring-platform/views/components/blocks/NeedHelp.vue'
import PendingMentorshipRequests from '@mentoring-platform/views/components/blocks/MenteeAdmin/PendingMentorshipRequests.vue'

export default {
  components: {
    BCol,
    BRow,
    CurrentMentorship,
    MyPackages,
    NeedHelp,
    PendingMentorshipRequests,
    WelcomeMessage
  },
  data() {
    return {
      
    }
  },
  computed: {
    mentee() {
      return this.$store.getters['user/getUserData'].mentee
    }
  }
}
</script>

<style lang="scss">
.mentee-mentorships{

  .no-data-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}
</style>
