<template>
  <div v-if="currentMentorship" class="d-md-flex flex-md-row">
    <div class="col-sm-12 col-md-8 pl-0">
      <mentorship-duration class="mb-1" :from="currentMentorship.started_at_date" :to="currentMentorship.ended_at_date" />
      <b-card-title>
        {{ title }}
      </b-card-title>
      <b-card-text>
        {{ $t('Below you can see your completed sessions according to your mentee.') }}
      </b-card-text>
      <b-card-group>
        <mentorship-session completed v-for="(session, index) in currentMentorship.completed_sessions" :key="'completed' + index" />
        <mentorship-session v-for="(session, index) in remainingSessions" :key="'remaining' + index" />
      </b-card-group>
      <div class="d-flex">
        <b-card-text class="mr-1 mb-1">
          {{ $t('Mentorship progress') }}
        </b-card-text>        
      </div>
      <b-progress :max="maxProgress">
        <b-progress-bar :value="currentMentorship.progress_percentage"></b-progress-bar>
      </b-progress>
    </div>
    <div class="col-sm-12 col-md-4 d-flex flex-column align-items-center mentorship-partner-info">
      <mentee-basic-info :mentorship="currentMentorship" />
      <slot name="cta"></slot>
    </div>
  </div>
</template>

<script>
import {
  BCardGroup,
  BCardText,
  BCardTitle,
  BProgress,
  BProgressBar
} from 'bootstrap-vue'
import MenteeBasicInfo from '@mentoring-platform/views/components/blocks/MentorMentorships/MenteeBasicInfo.vue'
import MentorshipDuration from '@mentoring-platform/views/components/blocks/MentorshipDuration.vue'
import MentorshipSession from '@mentoring-platform/views/components/blocks/MentorshipSession.vue'

export default {
  components: {
    BCardGroup,
    BCardText,
    BCardTitle,
    BProgress,
    BProgressBar,
    MenteeBasicInfo,
    MentorshipDuration,
    MentorshipSession
  },
  props: {
    title: {
      type: String,
      required: true
    },
    currentMentorship: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      maxProgress: 100
    }
  },
  computed: {
    remainingSessions() {
      return this.currentMentorship.total_sessions - this.currentMentorship.completed_sessions
    }
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .mentorship-partner-info {
    border-left: 1px solid #ebe9f1;
  }
}
</style>
