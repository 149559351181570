<template>
  <b-card v-if="currentMentorship" no-body class="d-md-flex flex-md-row">
    <div class="col-sm-12 col-md-8">
      <b-card-body>
        <mentorship-duration class="mb-1" :from="currentMentorship.started_at_date" :to="currentMentorship.ended_at_date" />
        <b-card-title class="font-weight-bolder">
          {{ title }}
        </b-card-title>
        <b-card-text>
          {{ $t('Define how many sessions you have done with your mentor:') }}
        </b-card-text>
        <b-card-group>
          <mentorship-session completed v-for="(session, index) in currentMentorship.completed_sessions" :key="'completed' + index" @reset-session="resetSession" />
          <mentorship-session v-for="(session, index) in remainingSessions" :key="'remaining' + index" @session-completed="sessionCompleted" />
        </b-card-group>
        <div class="d-flex">
          <b-card-text class="mr-1 mb-1">
            {{ $t('Mentorship progress') }}
          </b-card-text>
        </div>
        <b-progress :max="maxProgress">
          <b-progress-bar :value="currentMentorship.progress_percentage"></b-progress-bar>
        </b-progress>
      </b-card-body>
    </div>
    <div class="col-sm-12 col-md-4 d-flex flex-column mb-1 align-items-center mentorship-partner-info">
      <mentor-basic-info :mentorship="currentMentorship" />
      <b-button
        v-if="$can('read', 'mentee')"
        variant="primary"
        :href="rateMentorshipUrls.menteeUrl"
        target="_blank"
      >
        {{ $t('Rate mentorship') }}
      </b-button>
      <div v-else-if="$can('read', 'admin')" class="d-flex flex-column align-items-center flex-xl-row">
        <b-button class="mb-1 mb-xl-0 mr-xl-1" variant="outline-danger" @click="stopMentorship(currentMentorship)">
          {{ $t('Stop mentorship') }}
        </b-button>
        <b-button variant="primary" @click="completeMentorship(currentMentorship)">
          {{ $t('Complete mentorship') }}
        </b-button>
      </div>
    </div>
    <stop-mentorship-modal v-if="$can('read', 'admin')" ref="stop-mentorship-modal" />
    <complete-mentorship-modal v-if="$can('read', 'admin')" ref="complete-mentorship-modal" />
  </b-card>
  <b-card v-else no-body>
    <b-card-header>
      <b-card-title class="font-weight-bolder">
        {{ title }}
      </b-card-title>
    </b-card-header>
    <b-card-body body-class="no-data-card-body">
      <feather-icon icon="UsersIcon" size="25" />
      <b-card-text class="mb-1">
        {{ $t('You do not have any mentorship yet') }}
      </b-card-text>
      <b-button v-if="$can('read', 'mentee')" variant="primary" :to="{ name: 'our-mentors' }">
        {{ $t('View mentors') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardGroup,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BProgress,
  BProgressBar,
  BRow
} from 'bootstrap-vue'
import MentorBasicInfo from '@mentoring-platform/views/components/blocks/MenteeMentorships/MentorBasicInfo.vue'
import MentorshipDuration from '@mentoring-platform/views/components/blocks/MentorshipDuration.vue'
import MentorshipSession from '@mentoring-platform/views/components/blocks/MentorshipSession.vue'
import { useCurrentMentorshipRepository } from '@/mentoring-platform/composables'
import StopMentorshipModal from '@mentoring-platform/views/components/blocks/MentorAdmin/StopMentorshipModal'
import CompleteMentorshipModal from '@mentoring-platform/views/components/blocks/MentorAdmin/CompleteMentorshipModal'
import { rateMentorshipUrls } from '@mentoring-platform/constants'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardGroup,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BProgress,
    BProgressBar,
    BRow,
    MentorBasicInfo,
    MentorshipDuration,
    MentorshipSession,
    StopMentorshipModal,
    CompleteMentorshipModal
  },
  props: {
    title: {
      type: String,
      required: true
    },
    mentee: {
      type: Object
    }
  },
  data() {
    return {
      maxProgress: 100,
      rateMentorshipUrls
    }
  },
  computed: {
    remainingSessions() {
      return this.currentMentorship.total_sessions - this.currentMentorship.completed_sessions
    },
  },
  created() {
    this.fetchCurrent()
  },
  beforeMount() {
    this.$bus.$on('current-mentorship-stopped-by-admin', this.fetchCurrent)
    this.$bus.$on('new-mentorship-created-for-mentee-by-admin', this.fetchCurrent)
  },
  beforeDestroy() {
    this.$bus.$off('current-mentorship-stopped-by-admin', this.fetchCurrent)
    this.$bus.$off('new-mentorship-created-for-mentee-by-admin', this.fetchCurrent)
  },
  methods: {
    fetchCurrent() {
      if(this.$can('read', 'mentee')) {
        this.getCurrentMentorship()
      }
      else if(this.$can('read', 'admin')) {
        this.getCurrentMentorshipForMentee(this.mentee)
      }
    },
    sessionCompleted() {
      this.increaseCompletedSessions(this.mentee)
        .then(async response => {
          this.fetchCurrent()
          this.showSuccess(response)
        })
        .catch(error => {
          this.showError(error)
        })
    },
    resetSession() {
      this.decreaseCompletedSessions(this.mentee)
        .then(async response => {
          this.fetchCurrent()
          this.showSuccess(response)
        })
        .catch(error => {
          this.showError(error)
        })
    },
    stopMentorship(mentorship) {
      if(this.$can('read', 'admin')) {
        this.$refs['stop-mentorship-modal'].show(mentorship)
      }
    },
    completeMentorship(mentorship) {
      if(this.$can('read', 'admin')) {
        this.$refs['complete-mentorship-modal'].show(mentorship)
      }
    },
    showSuccess(response) {
      this.$root.$bvToast.toast(this.$t('Number of sessions updated!'), {
        title: this.$t('Confirmation'),
        variant: 'success',
        solid: true,
      })
    },
    showError(error) {
      this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
        title: this.$t('Caution'),
        variant: 'danger',
        solid: true,
      })
    }
  },
  setup() {
    const {
      currentMentorship,
      getCurrentMentorship,
      getCurrentMentorshipForMentee,
      increaseCompletedSessions,
      decreaseCompletedSessions
    } = useCurrentMentorshipRepository()

    return {
      currentMentorship,
      getCurrentMentorship,
      getCurrentMentorshipForMentee,
      increaseCompletedSessions,
      decreaseCompletedSessions
    }
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .mentorship-partner-info {
    border-left: 1px solid #ebe9f1;
  }
}
</style>
