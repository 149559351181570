<template>
  <b-card>
    <b-tabs>
      <b-tab :title="$t('Mentoring')" active>
        <b-card-title>
          {{ $t('Previous mentorships') }}
        </b-card-title>
        <div v-if="previousMentorshipsOfMentoring.length">
          <previous-mentorships-table
            :previousMentorships="previousMentorshipsOfMentoring"
            @show-mentee-details="showMenteeDetails"
          />
        </div>
        <div v-else class="no-data-card-body">
          <feather-icon
            icon="ClockIcon"
            size="25"
          />
          <b-card-text>
            {{ $t('You do not have any previous mentorships') }}
          </b-card-text>
        </div>
      </b-tab>
      <b-tab :title="$t('Coaching')" v-if="mentor.is_coach">
        <b-card-title>
          {{ $t('Previous mentorships') }}
        </b-card-title>
        <div v-if="previousMentorshipsOfCoaching.length">
          <previous-mentorships-table
            :previousMentorships="previousMentorshipsOfCoaching"
            @show-mentee-details="showMenteeDetails"
          />
        </div>
        <div v-else class="no-data-card-body">
          <feather-icon
            icon="ClockIcon"
            size="25"
          />
          <b-card-text>
            {{ $t('You do not have any previous mentorships') }}
          </b-card-text>
        </div>
      </b-tab>
    </b-tabs>
    <mentee-details-modal ref="mentee-details-modal" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardTitle,
  BTable,
  BTab,
  BTabs
} from 'bootstrap-vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import MenteeDetailsModal from '@mentoring-platform/views/components/blocks/MentorMentorships/MenteeDetailsModal.vue'
import PreviousMentorshipsTable from '@mentoring-platform/views/components/blocks/MentorMentorships/PreviousMentorshipsTable.vue'
import { useMentorMentorships } from '@mentoring-platform/composables'

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BTable,
    BTab,
    BTabs,
    MenteeDetailsModal,
    PreviousMentorshipsTable
  },
  filters: {
    upper,
    formatGreekDate
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    previousMentorshipsOfMentoring() {
      return this.previousMentorships.filter(request => request.is_coaching == false)
    },
    previousMentorshipsOfCoaching() {
      return this.previousMentorships.filter(request => request.is_coaching == true)
    }
  },
  beforeMount() {
    this.$bus.$on('previous-mentorships-list-needs-refresh', this.refreshList)
  },
  beforeDestroy() {
    this.$bus.$off('previous-mentorships-list-needs-refresh', this.refreshList)
  },
  methods: {
    refreshList() {
      this.getPreviousMentorships(this.mentor)
    },
    showMenteeDetails(request) {
      this.$refs["mentee-details-modal"].show(request);
    }
  },
  setup(props) {
    const { previousMentorships, getPreviousMentorships } = useMentorMentorships()
    getPreviousMentorships(props.mentor)

    return {
      previousMentorships,
      getPreviousMentorships
    }
  }
}
</script>

<style scoped>

</style>
