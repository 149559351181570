<template>
  <b-modal
    id="stop-mentorship-modal"
    ref="stop-mentorship-modal"
    centered
    size="md"
    :title="$t('Confirm mentorship stop')"
    title-class="font-weight-bolder"
    :ok-title="$t('Yes, i am sure')"
    @ok.prevent="deletionConfirmed"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-primary"
  >
    <b-card-text class="p-2">
      {{ $t('Stop mentorship confirmation message') }}
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal
} from "bootstrap-vue";
import { useMentorship } from '@mentoring-platform/composables'

export default {
  components: {
    BCardText,
    BModal
  },
  data() {
    return {
      mentorship: null
    }
  },
  methods: {
    show(mentorship) {
      this.mentorship = mentorship
      this.$refs['stop-mentorship-modal'].show()
    },
    deletionConfirmed() {
      const payload = {
        id: this.mentorship.id
      }
      this.stopMentorship(payload)
        .then(response => {
          this.$refs['stop-mentorship-modal'].hide()
          this.$bus.$emit('previous-mentorships-list-needs-refresh')
          this.$bus.$emit('current-mentorship-stopped-by-admin')
          this.$root.$bvToast.toast(this.$t('Mentorship successfully stopped'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    }
  },
  setup() {
    const { stopMentorship } = useMentorship()

    return {
      stopMentorship
    }
  }
};
</script>

<style lang="scss">

</style>
