<template>
  <b-modal
    id="mentor-details-modal"
    ref="mentor-details-modal"
    class="p-2"
    centered
    size="lg"
    hide-footer
    @hidden="onHidden"
  >
    <div v-if="mentorship">
      <div class="mb-2">
        <h2>{{ getMentorVisibleFullName(mentor) }}</h2>
        {{ getMentorVisibleProfessionalTitle(mentor) }}
      </div>
      <div class="border px-2 py-1 mb-1">
        <feather-icon icon="PhoneIcon" size="18" />
        <span class="ml-1 mr-3">{{ mentor.user.phone }}</span>
        <feather-icon icon="MailIcon" size="18" />
        <span class="ml-1">{{ mentor.user.email }}</span>
      </div>
      <div class="border px-2 py-1 mb-1">
        {{ getMentorVisibleDescription(mentor) }}
      </div>
      <div class="border p-2 mb-1">
        <div class="mb-2 mentor-info">
          <b-card-text class="font-weight-bolder">
            {{ $t('Location') }}
          </b-card-text>
          <b-card-text v-if="mentorIsGreek">
            {{ $t('State') }} {{ mentor.user.county.name }}
          </b-card-text>
          <b-card-text v-else>
            {{ mentor.user.country.name }}
          </b-card-text>
        </div>
        <div class="mb-1 mentor-info">
          <b-card-text class="font-weight-bolder">
            {{ $t('Work status') }}
          </b-card-text>
          <b-card-text v-if="mentor.user.work_status">
            {{  mentor.user.work_status.description }}
          </b-card-text>
          <b-card-text v-else>
            {{  mentor.user.custom_work_status }}
          </b-card-text>
        </div>
        <div class="mb-2 mentor-info">
          <b-card-text class="font-weight-bolder">
            {{ $t('Industry') }}
          </b-card-text>
          <b-card-text v-for="(industry, index) in mentor.user.industries" :key="index">
            {{ industry.name }}
          </b-card-text>
        </div>
      </div>
      <div class="mb-3 mentor-stats">
        <mentor-stats :mentor="mentor" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BCardTitle,
  BModal
} from "bootstrap-vue";
import { useCountryRepository, useMentorHelpers } from '@mentoring-platform/composables'
import MentorStats from '@mentoring-platform/views/components/blocks/Mentor/MentorStats.vue'

export default {
  components: {
    BCardText,
    BCardTitle,
    BModal,
    MentorStats
  },
  data() {
    return {
      mentorship: null
    }
  },
  computed: {
    mentor() {
      return this.mentorship.mentor
    },
    mentorIsGreek() {
      return this.mentor.user.country_id == this.countryIdOfGreece
    }
  },
  methods: {
    show(mentorship) {
      this.mentorship = mentorship
      this.$refs['mentor-details-modal'].show()
    },
    onHidden() {
      this.mentorship = null
    }
  },
  setup() {
    const { getMentorVisibleFullName, getMentorVisibleProfessionalTitle, getMentorVisibleDescription } = useMentorHelpers()
    const { countryIdOfGreece } = useCountryRepository()

    return {
      countryIdOfGreece,
      getMentorVisibleDescription,
      getMentorVisibleFullName,
      getMentorVisibleProfessionalTitle
    }
  },
};
</script>

<style lang="scss">
.mentor-stats {

  div.card {
    border: 1px solid #ebe9f1;
    margin-top: 0 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

}
</style>
