<template>
  <div>
    <mentor-mentorships v-if="$can('read', 'mentor')" />
    <mentee-mentorships v-if="$can('read', 'mentee')" />
  </div>
</template>

<script>
import MentorMentorships from '@mentoring-platform/views/components/blocks/MentorMentorships/MentorMentorships.vue'
import MenteeMentorships from '@mentoring-platform/views/components/blocks/MenteeMentorships/MenteeMentorships.vue'

export default {
  components: {
    MentorMentorships,
    MenteeMentorships,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
