<template>
  <b-table :fields="fields" :items="mentorshipRequests" responsive>
    <template #head()="{label}">
      {{ label | upper }}
    </template>
    <template #cell(package_name)="{item}">
      {{ $t(item.package_name) }}
    </template>
    <template #cell(created_at)="{item}">
      {{ item.created_at | formatGreekDate }}
    </template>
    <template #cell(mentee)="{item}">
      <b-card-text class="text-primary cursor-pointer" @click="$emit('show-mentee-details', item)">
        {{ item.mentee.user.name }} {{ item.mentee.user.surname }}
      </b-card-text>
    </template>
    <template #cell(view_request)="{item}">
      <b-button variant="primary" class="mr-1" @click="$emit('show-mentee-details', item)">
          {{ $t('View mentorship request') }}
        </b-button>
    </template>
    <template #cell(actions)="data">
      <div class="mentorship-request-actions">
        <b-button variant="outline-primary" class="mr-1" @click="$emit('reject-request', data.item)">
          {{ $t('Rejection') }}
        </b-button>
        <b-button variant="primary" @click="$emit('approve-request', data.item)">
          {{ $t('Approval') }}
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BButton,
  BCardText,
  BTable
} from 'bootstrap-vue'
import ApproveRequestModal from '@mentoring-platform/views/components/blocks/MentorMentorships/ApproveRequestModal.vue'
import RejectRequestModal from '@mentoring-platform/views/components/blocks/MentorMentorships/RejectRequestModal.vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import MenteeDetailsModal from '@mentoring-platform/views/components/blocks/MentorMentorships/MenteeDetailsModal.vue'

export default {
  components: {
    ApproveRequestModal,
    BButton,
    BCardText,
    BTable,
    MenteeDetailsModal,
    RejectRequestModal
  },
  props: {
    mentorshipRequests: {
      type: Array,
      required: true
    }
  },
  filters: {
    formatGreekDate,
    upper
  },
  data() {
    return {

    }
  },
  computed: {
    fields() {
      return [{
        key: 'package_name',
        label: this.$t('Package')
      }, {
        key: 'created_at',
        label: this.$t('Request date')
      }, {
        key: 'mentee',
        label: this.$t('Mentee')
      }, {
        key: 'view_request',
        label: ''
      }, {
        key: 'actions',
        label: this.$t('Actions')
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.mentorship-request-actions {
  min-width: 250px;
}
</style>
