<template>
  <b-table
    :fields="fields"
    :items="mentorshipRequests"
    responsive
  >
    <template #head()="{label}">
      {{ label | upper }}
    </template>
    <template #cell(created_at)="{item}">
      {{ item.created_at | formatGreekDate }}
    </template>
    <template #cell(mentor)="{item}">
      <b-link @click="$emit('show-mentor-details', item)">
        {{ getMentorVisibleFullName(item.mentor) }}
      </b-link>
    </template>
  </b-table>
</template>

<script>
import {
  BLink,
  BTable
} from 'bootstrap-vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import { useMentorHelpers } from '@mentoring-platform/composables'

export default {
  components: {
    BLink,
    BTable
  },
  filters: {
    formatGreekDate,
    upper
  },
  props: {
    mentorshipRequests: {
      type: Array,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    fields() {
      return [{
        key: 'created_at',
        label: this.$t('Request date')
      }, {
        key: 'mentor',
        label: this.$t('Mentor')
      }]
    }
  },
  setup() {
    const { getMentorVisibleFullName } = useMentorHelpers()

    return {
      getMentorVisibleFullName
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
