<template>
  <b-card>
    <b-tabs>
      <b-tab :title="$t('Mentoring')" active>
        <div v-if="currentMentoring">
          <current-mentorship-item :title="title" :current-mentorship="currentMentoring">
            <template #cta v-if="$can('read', 'mentor')">
              <b-button
                variant="primary"
                :href="rateMentorshipUrls.mentorUrl"
                target="_blank"
              >
                {{ $t('Rate mentorship') }}
              </b-button>
            </template>
            <template #cta v-else-if="$can('read', 'admin')">
              <div class="d-flex flex-column align-items-center flex-xl-row">
                <b-button class="mb-1 mb-xl-0 mr-xl-1" variant="outline-danger" @click="stopMentorship(currentMentoring)">
                  {{ $t('Stop mentorship') }}
                </b-button>
                <b-button variant="primary" @click="completeMentorship(currentMentoring)">
                  {{ $t('Complete mentorship') }}
                </b-button>
              </div>
            </template>
          </current-mentorship-item>
        </div>
        <div v-else>
          <b-card-title>
            {{ title }}
          </b-card-title>
          <div class="no-data-card-body">
            <feather-icon icon="UsersIcon" size="25" />
            <b-card-text>
              {{ $t('You do not have any current mentorship') }}
            </b-card-text>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('Coaching')" v-if="mentor.is_coach">
        <div v-if="currentCoaching">
          <current-mentorship-item :title="title" :current-mentorship="currentCoaching">
            <template #cta v-if="$can('read', 'mentor')">
              <b-button
                variant="primary"
                :href="rateMentorshipUrls.mentorUrl"
                target="_blank"
              >
                {{ $t('Rate mentorship') }}
              </b-button>
            </template>
            <template #cta v-else-if="$can('read', 'admin')">
              <div class="d-flex flex-column align-items-center flex-xl-row">
                <b-button class="mb-1 mb-xl-0 mr-xl-1" variant="outline-danger" @click="stopMentorship(currentCoaching)">
                  {{ $t('Stop mentorship') }}
                </b-button>
                <b-button variant="primary" @click="completeMentorship(currentCoaching)">
                  {{ $t('Complete mentorship') }}
                </b-button>
              </div>
            </template>
          </current-mentorship-item>
        </div>
        <div v-else>
          <b-card-title>
            {{ title }}
          </b-card-title>
          <div class="no-data-card-body">
            <feather-icon icon="UsersIcon" size="25" />
            <b-card-text>
              {{ $t('You do not have any current mentorship') }}
            </b-card-text>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <stop-mentorship-modal v-if="$can('read', 'admin')" ref="stop-mentorship-modal" />
    <complete-mentorship-modal v-if="$can('read', 'admin')" ref="complete-mentorship-modal" />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardGroup,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BRow,
  BTab,
  BTabs
} from 'bootstrap-vue'
import CurrentMentorshipItem from '@mentoring-platform/views/components/blocks/MentorMentorships/CurrentMentorshipItem.vue'
import { useCurrentMentorshipRepository } from '@/mentoring-platform/composables'
import StopMentorshipModal from '@mentoring-platform/views/components/blocks/MentorAdmin/StopMentorshipModal'
import CompleteMentorshipModal from '@mentoring-platform/views/components/blocks/MentorAdmin/CompleteMentorshipModal'
import { rateMentorshipUrls } from '@mentoring-platform/constants'

export default {
  components: {
    BButton,
    BCard,
    BCardGroup,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BRow,
    BTab,
    BTabs,
    CurrentMentorshipItem,
    StopMentorshipModal,
    CompleteMentorshipModal
  },
  props: {
    title: {
      type: String,
      required: true
    },
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rateMentorshipUrls
    }
  },
  computed: {
    currentMentoring() {
      if(this.currentMentorship) {
        return this.currentMentorship.find(mentorship => mentorship.is_coaching === false)
      }
      return null
    },
    currentCoaching() {
      if(this.currentMentorship) {
        return this.currentMentorship.find(mentorship => mentorship.is_coaching === true)
      }
      return null
    }
  },
  created() {
    this.getCurrent()
  },
  beforeMount() {
    this.$bus.$on('mentorship-request-approved', this.getCurrent)
    this.$bus.$on('current-mentorship-stopped-by-admin', this.getCurrent)
  },
  beforeDestroy() {
    this.$bus.$off('mentorship-request-approved', this.getCurrent)
    this.$bus.$off('current-mentorship-stopped-by-admin', this.getCurrent)
  },
  methods: {
    getCurrent() {
      if(this.$can('read', 'mentor')) {
        this.getCurrentMentorship()
      }
      else if(this.$can('read', 'admin')) {
        this.getCurrentMentorshipForMentor(this.mentor)
      }
    },
    stopMentorship(mentorship) {
      if(this.$can('read', 'admin')) {
        this.$refs['stop-mentorship-modal'].show(mentorship)
      }
    },
    completeMentorship(mentorship) {
      if(this.$can('read', 'admin')) {
        this.$refs['complete-mentorship-modal'].show(mentorship)
      }
    }
  },
  setup() {
    const { currentMentorship, getCurrentMentorship, getCurrentMentorshipForMentor } = useCurrentMentorshipRepository()

    return {
      currentMentorship,
      getCurrentMentorship,
      getCurrentMentorshipForMentor
    }
  }
}
</script>

<style scoped>

</style>
