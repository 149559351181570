<template>
  <b-card no-body>
    <b-card-header class="pb-1">
      <b-card-title class="font-weight-bolder">
        {{ $t('My packages') }}
      </b-card-title>
    </b-card-header>
    <b-card-body
      v-if="userPackages.length"
    >
      <b-table
        :fields="fields"
        :items="userPackages"
        responsive
      >
        <template #head()="{label}">
          {{ label | upper }}
        </template>
        <template #cell(description)="{item}">
          {{ $t(item.description) }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="packageStatusToColorVariant(data.item.status)">
            {{ packageStatusToText(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(mentor)="{item}">
          <template v-if="item.status == packageStatuses.PACKAGE_STATUS_RUNNING || item.status == packageStatuses.PACKAGE_STATUS_COMPLETED">
            <div class="d-flex flex-row mentorship-info">
              <div class="mr-1">
                <b-avatar
                  size="40"
                  class="grayscale-avatar"
                  :src="item.mentor.user.photo_full_path"
                />
              </div>
              <div class="d-flex flex-column">
                <b-link :to="{ name: 'mentor-page', params: { id: item.mentor.id } }">
                  {{ getMentorVisibleFullName(item.mentor) }}
                </b-link>
                <div class="footnote">
                  {{ item.started_at_date | formatGreekDate({ month: 'numeric', year: 'numeric', day: '2-digit' }) }} - {{ item.ended_at_date | formatGreekDate({ month: 'numeric', year: 'numeric', day: '2-digit' }) }}
                </div>
              </div>
            </div>
          </template>
        </template>
        <template #cell(rete-mentorship)="{item}">
          <b-button
            v-if="item.status == packageStatuses.PACKAGE_STATUS_RUNNING || item.status == packageStatuses.PACKAGE_STATUS_COMPLETED"
            variant="primary"
            :href="rateMentorshipUrls.menteeUrl"
            target="_blank"
          >
            {{ $t('Rate mentorship') }}
          </b-button>
        </template>
      </b-table>
    </b-card-body>
    <b-card-body
      v-else
      body-class="no-data-card-body"
      class="mb-3"
    >
      <feather-icon
        icon="PackageIcon"
        size="25"
      />
      <b-card-text class="mb-1">
        {{ $t('You do not have any purchased package yet.') }}
      </b-card-text>
      <b-button
        v-if="$can('read', 'mentee')"
        variant="primary"
        :to="{ name: 'mentoring-packages' }"
      >
        {{ $t('View mentoring packages') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BLink,
  BTable
} from 'bootstrap-vue'
import { useUserPackagesRepository, useMentorHelpers } from '@mentoring-platform/composables'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import { packageStatuses, rateMentorshipUrls } from '@mentoring-platform/constants'

export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BLink,
    BTable
  },
  filters: {
    upper,
    formatGreekDate
  },
  data() {
    return {
      packageStatuses,
      rateMentorshipUrls
    }
  },
  computed: {
    fields() {
      return [{
        key: 'description',
        label: this.$t('Package'),
        tdClass: 'font-weight-bolder'
      }, {
        key: 'purchased_at',
        label: this.$t('Purchase date')
      }, {
        key: 'status',
        label: this.$t('Status')
      }, {
        key: 'mentor',
        label: this.$t('Mentorship')
      }, {
        key: 'rete-mentorship',
        label: this.$t('Testimonial')
      }]
    }
  },
  setup() {
    const {
      getMentorVisibleFullName
    } = useMentorHelpers()
    const {
      userPackages,
      getUserPackages,
      packageStatusToText,
      packageStatusToColorVariant
    } = useUserPackagesRepository()
    getUserPackages()

    return {
      getMentorVisibleFullName,
      userPackages,
      getUserPackages,
      packageStatusToText,
      packageStatusToColorVariant
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  .mentorship-info {
    min-width: 200px;
  }
}
</style>
