<template>
  <b-modal
    id="mentee-details-modal"
    ref="mentee-details-modal"
    class="p-2"
    centered
    size="lg"
    hide-footer
    @hidden="onHidden"
  >
    <div v-if="mentorship">
      <div class="mb-2">
        <h2>{{ mentee.user.name }} {{ mentee.user.surname }}</h2>
        {{ mentee.user.professional_title }}
      </div>
      <div class="border px-2 py-1  mb-1">
        <feather-icon icon="PhoneIcon" size="18" />
        <span class="ml-1 mr-3">{{ mentee.user.phone }}</span>
        <feather-icon icon="MailIcon" size="18" />
        <span class="ml-1">{{ mentee.user.email }}</span>
      </div>
      <div class="border p-2 mb-1">
        <div class="mb-2 mentor-info">
          <b-card-text class="font-weight-bolder">
            {{ $t('Location') }}
          </b-card-text>
          <b-card-text v-if="menteeIsGreek">
            {{ $t('State') }} {{ $t(mentee.user.county.name) }}
          </b-card-text>
          <b-card-text v-else>
            {{ $t(mentee.user.country.name) }}
          </b-card-text>
        </div>
        <div class="mb-2 mentor-info">
          <b-card-text class="font-weight-bolder">
            {{ $t('Work status') }}
          </b-card-text>
          <b-card-text v-if="mentee.user.work_status">
            {{ $t(mentee.user.work_status.description) }}
          </b-card-text>
          <b-card-text v-else>
            {{  mentee.user.custom_work_status }}
          </b-card-text>
        </div>
        <div class="mb-2 mentor-info">
          <b-card-text class="font-weight-bolder">
            {{ $t('Industry') }}
          </b-card-text>
          <b-card-text v-for="(industry, index) in mentee.user.industries" :key="index">
            {{ $t(industry.name) }}
          </b-card-text>
        </div>
        <div class="mentor-info">
          <b-card-text class="font-weight-bolder">
            {{ $t('Years of experience') }}
          </b-card-text>
          <b-card-text>
            {{  mentee.user.years_of_experience }}
          </b-card-text>
        </div>
      </div>
      <div class="border p-2 mb-2">
        <b-card-text class="font-weight-bolder">
          {{ $t('Do you search for mentoring on a specific subject on your job?') }}
        </b-card-text>
        {{ mentorship.reason_for_buying }}
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BCardTitle,
  BModal
} from "bootstrap-vue";
import { useCountryRepository } from '@mentoring-platform/composables'

export default {
  components: {
    BCardText,
    BCardTitle,
    BModal
  },
  data() {
    return {
      mentorship: null
    }
  },
  computed: {
    mentee() {
      return this.mentorship.mentee
    },
    menteeIsGreek() {
      return this.mentee.user.country_id == this.countryIdOfGreece
    },
  },
  setup() {
    const { countryIdOfGreece } = useCountryRepository()

    return {
      countryIdOfGreece
    }
  },
  methods: {
    show(mentorship) {
      this.mentorship = mentorship
      this.$refs["mentee-details-modal"].show();
    },
    onHidden() {
      this.mentorship = null
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
