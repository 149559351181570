import { render, staticRenderFns } from "./MenteeBasicInfo.vue?vue&type=template&id=045ef2b0&scoped=true&"
import script from "./MenteeBasicInfo.vue?vue&type=script&lang=js&"
export * from "./MenteeBasicInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "045ef2b0",
  null
  
)

export default component.exports