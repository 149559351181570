<template>
  <div>
    <b-card-body class="d-flex flex-column align-items-center">
      <b-avatar
        size="60"
        :src="mentor.user.photo_full_path"
        class="mt-1 mb-2 cursor-pointer grayscale-avatar"
        @click.native="showMentorDetails"
      />
      <b-card-text>
        <b-link @click.native="showMentorDetails">
          {{ mentorFullName }}
        </b-link>
      </b-card-text>
      <b-card-text>
        {{ getMentorVisibleProfessionalTitle(mentor) }}
      </b-card-text>
    </b-card-body>
    <mentor-details-modal ref="mentor-details-modal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BCardBody,
  BCardText,
  BLink
} from 'bootstrap-vue'
import { useMentorHelpers } from '@mentoring-platform/composables'
import MentorDetailsModal from '@mentoring-platform/views/components/blocks/MenteeMentorships/MentorDetailsModal.vue'

export default {
  components: {
    BAvatar,
    BCardBody,
    BCardText,
    BLink,
    MentorDetailsModal
  },
  props: {
    mentorship: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    mentor() {
      return this.mentorship.mentor
    },
    mentorFullName() {
      return this.getMentorVisibleFullName(this.mentor)
    }
  },
  methods: {
    showMentorDetails() {
      this.$refs['mentor-details-modal'].show(this.mentorship)
    }
  },
  setup() {
    const { getMentorVisibleFullName, getMentorVisibleProfessionalTitle } = useMentorHelpers()

    return {
      getMentorVisibleFullName,
      getMentorVisibleProfessionalTitle
    }
  }
}
</script>

<style scoped>

</style>
