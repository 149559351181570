<template>
  <svg viewBox="-5 -5 120 120">
    <circle cx="50" cy="50" r="50" :class="circleClass" />
    <foreignObject x="0" y="0" height="100px" width="100px">
      <div v-if="!completed && menteeView" class="feather-wrapper" v-b-tooltip.hover.top="$t('Mark session as completed')">
        <feather-icon :icon="icon" :class="iconClass" class="incomplete" @click="editSession" />
      </div>
      <div v-else-if="!completed" class="feather-wrapper">
        <feather-icon :icon="icon" :class="iconClass" @click="editSession" />
      </div>
      <div v-else class="feather-wrapper">
        <feather-icon :icon="icon" :class="iconClass" @click="editSession" />
      </div>
    </foreignObject>
  </svg>
</template>

<script>
import {
  VBTooltip
} from 'bootstrap-vue'

export default {
  components: {
    VBTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    completed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  computed: {
    menteeView() {
      return this.$can('read', 'mentee') || (this.$can('read', 'admin') && this.$route.name == 'admin-mentee-page')
    },
    sessionCanBeCompleted() {
      return !this.completed && this.menteeView
    },
    icon() {
      if(this.completed) {
        return 'CheckIcon'
      }
      else if(this.menteeView) {
        return 'PlusIcon'
      }
      else {
        return ''
      }
    },
    iconClass() {
      if(this.menteeView) {
        return 'cursor-pointer'
      }
      return ''
    },
    circleClass() {
      return this.completed ? 'solid' : 'dotted'
    },
  },
  methods: {
    completeSession() {
      this.$emit('session-completed')
    },
    resetSession() {
      this.$emit('reset-session')
    },
    editSession() {
      if(this.sessionCanBeCompleted) {
        this.completeSession()
      }
      else {
        this.resetSession()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'scss/variables/variables';

svg{
  height: 100px;
  width: 100px;
}

svg.feather{
  height: 50px;
  width: 50px;
}

circle {
  fill: #F5F5F5;
  stroke: #BDBDBD;
  stroke-width: 2;
}

.dotted {
  stroke-dasharray: 0.1, 6.2832;
  stroke-linecap: round;
}

.solid {
  stroke-dasharray: none;
  stroke: $primary
}

div.feather-wrapper {
  height: 100px;
  width: 100px;
  color: #444444;
  font-weight: bold;
  text-align: center;
  line-height: 100px;
}

.incomplete {
  color:#BDBDBD;
}
</style>
