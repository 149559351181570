<template>
  <b-row>
    <b-col
      xl="3"
      md="6"
    >
      <statistic-card-vertical
        class="mb-0 mt-2"
        color="primary"
        icon="HeartIcon"
        :statistic="mentor.member_from"
        :statistic-title="$t('Mentor from')"
      />
    </b-col>
    <b-col
      xl="3"
      md="6"
    >
      <statistic-card-vertical
        class="mb-0 mt-2"
        color="success"
        icon="AwardIcon"
        :statistic="mentor.user.years_of_experience"
        :statistic-title="$t('Years of experience')"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow
} from 'bootstrap-vue'
import StatisticCardVertical from '@mentoring-platform/views/components/blocks/StatisticCardVertical.vue'

export default {
  components: {
    BCol,
    BRow,
    StatisticCardVertical
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>